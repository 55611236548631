import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },

  customButton: {
    marginLeft: 5,
  },

  customFontOpen: {
    textTransform: "none",
    fontSize: 15,
    letterSpacing: "0.39998px",
    marginLeft: "2px",
    marginRight: "12px",
  },

  customFontPending: {
    textTransform: "none",
    fontSize: 15,
    letterSpacing: "0.39998px",
    marginLeft: "-5px",
    marginRight: "12px",
  },

  customFontResolved: {
    textTransform: "none",
    fontSize: 15,
    letterSpacing: "0.39998px",
    marginLeft: -110,
  },

  selected: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08) !important",
    },
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 7,
    padding: 4,
    marginRight: theme.spacing(1),
    maxWidth: 630,
    height: 45,
  },

  inputGrid: {
    background: "#F0F2F5",
    padding: 20,
  },

  indicator: {
    backgroundColor: (props) => (props.isResolved ? "#00A825" : "#2196F3"),
    height: "4px",
    borderRadius: '5px 5px 0 0',
    padding: "0 2px",
  },

  tabPanelHidden: {
    display:'none'
  }
}));