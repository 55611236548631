import React, { useState, useEffect, useReducer, useRef } from "react";

import { isSameDay, parseISO, format, isToday } from "date-fns";
import clsx from "clsx";

import {
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";

import MessageOptionsMenu from "../MessageOptionsMenu";

import { api } from "../../services/api";
import toastError from "../../errors/toastError";

import connectToSocket from "../../services/socket-io";

import AudioCors from "../AudioCors/AudioCors";
import OtherCors from "../OtherCors/OtherCors";
import messageListStyles from "./css/messageListStyles";
import ModalMedia from "../ModalMedia";
import MessageVideo from "../MessageVideo";
import MessageImage from "../MessageImage";
import LiveLocationCard from "../LiveLocationCard";

/* --------------------------------------------------------------------------
 * A lógica abaixo é exatamente a do seu arquivo "encaminhamento" original
 * -------------------------------------------------------------------------- */

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({
  ticketId,
  isGroup,
  setMessagesUpdated,
  onForwardingModeChange,
  quantityMessageSelected,
  isForwardingMode,
  onSelectedMessagesChange
}) => {

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);

  const [selectedMessage, setSelectedMessage] = useState({});
  const [selectedMessages, setSelectedMessages] = useState([]);


  // Controle de mídia no Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMediaUrl, setModalMediaUrl] = useState(null);
  const [modalMediaType, setModalMediaType] = useState(null);
  const [modalCurrentIndex, setModalCurrentIndex] = useState(null);

  const classes = messageListStyles({ isForwardingMode });

  const idGraf = localStorage.getItem('idGraf');

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
    currentTicketId.current = ticketId;
    onForwardingModeChange(false);
  }, [ticketId]);

  useEffect(() => {
    if (!isForwardingMode) {
      setSelectedMessages([]);
    }
  }, [isForwardingMode]);

  const hasMedia = (message) => {
    if (!message) return false;
    if (
      message.mediaUrl &&
      !["PENDING", "ERROR"].includes(message.mediaUrl.slice(-7)) &&
      message.mediaType !== "contactMessage"
    ) {
      return true;
    }
    return false;
  };


  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {
          const { data } = await api.get(`/messages/${ticketId}`, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          // Se estiver na primeira página e houver msgs
          if (pageNumber === 1 && data.messages.length > 0) {
            // Se há alguma mídia, espera um pouquinho p/ garantir load
            const hasAnyMedia = data.messages.some((msg) => hasMedia(msg));
            if (hasAnyMedia) {
              await new Promise((resolve) => setTimeout(resolve, 80));
            }
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [ticketId, pageNumber]);

  useEffect(() => {
    const socket = connectToSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on(`appMessage-${idGraf}`, (data) => {
      if (data.action === "create") {
        if (ticketId == data.message.ticket.id) {
          dispatch({ type: "ADD_MESSAGE", payload: data.message });
          scrollToBottom();
        }
      }
      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
      setMessagesUpdated(true);
    });

    socket.on("downloadedMedia", (data) => {
      if (data.action === "create") {
        if (ticketId == data.message.ticket.id) {
          dispatch({ type: "ADD_MESSAGE", payload: data.message });
          scrollToBottom();
        }
      }
      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
      setMessagesUpdated(true);
    });


    return () => {
      socket.disconnect();
    };
  }, [setMessagesUpdated, ticketId]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);

    setSelectedMessages((prevSelectedMessages) => {
      let newSelectedMessages;

      if (!prevSelectedMessages.includes(message.id)) {
        newSelectedMessages = [...prevSelectedMessages, message.id];
      } else {
        newSelectedMessages = prevSelectedMessages;
      }
      quantityMessageSelected(newSelectedMessages.length);

      return newSelectedMessages;
    });
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleFowardMessage = (messageId) => {
    setSelectedMessages((prevSelectedMessages) => {
      let newSelectedMessages;
      if (!prevSelectedMessages.includes(messageId)) {
        newSelectedMessages = [...prevSelectedMessages, messageId];
      } else {
        newSelectedMessages = prevSelectedMessages;
      }

      quantityMessageSelected(newSelectedMessages.length);
      onSelectedMessagesChange(newSelectedMessages);

      return newSelectedMessages;
    });

    onForwardingModeChange(true);
    setAnchorEl(null);
  };

  const handleToggleSelectMessage = (messageId) => {
    setSelectedMessages((prevSelectedMessages) => {
      let newSelectedMessages;
      if (prevSelectedMessages.includes(messageId)) {
        newSelectedMessages = prevSelectedMessages.filter((id) => id !== messageId);
      } else {
        newSelectedMessages = [...prevSelectedMessages, messageId];
      }

      quantityMessageSelected(newSelectedMessages.length);
      onSelectedMessagesChange(newSelectedMessages);

      return newSelectedMessages;
    });
  };

  /* ------------------------------------------------------------------
   * Modal de Imagem/Vídeo
   * ------------------------------------------------------------------ */
  const mediaList = messagesList
    .filter((msg) => msg.mediaType === "image" || msg.mediaType === "video")
    .map((msg) => ({ url: msg?.mediaUrl, type: msg.mediaType }));

  const imagesList = messagesList
    .filter((msg) => msg.mediaType === "image")
    .map((msg) => msg?.mediaUrl);

  const openModalMedia = (mediaUrl, mediaType = "image") => {
    const index = mediaList.findIndex(
      (m) => m.url === mediaUrl && m.type === mediaType
    );
    setModalCurrentIndex(index);
    setModalMediaUrl(mediaUrl);
    setModalMediaType(mediaType);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalMediaUrl(null);
    setModalMediaType(null);
    setModalCurrentIndex(null);
  };

  const handleModalNext = () => {
    if (!mediaList.length || modalCurrentIndex === null) return;
    const newIndex = (modalCurrentIndex + 1) % mediaList.length;
    setModalCurrentIndex(newIndex);
    setModalMediaUrl(mediaList[newIndex].url);
    setModalMediaType(mediaList[newIndex].type);
  };

  const handleModalPrev = () => {
    if (!mediaList.length || modalCurrentIndex === null) return;
    const newIndex = (modalCurrentIndex - 1 + mediaList.length) % mediaList.length;
    setModalCurrentIndex(newIndex);
    setModalMediaUrl(mediaList[newIndex].url);
    setModalMediaType(mediaList[newIndex].type);
  };



  const checkMessageLocation = (message) => {
    console.log('Checando', message)
    if (message.mediaType === "liveLocationMessage" ||
      message?.dataJson?.message?.liveLocationMessage) {

      return (
        <LiveLocationCard />
      );
    }

    if (
      (message?.mediaType === "locationMessage" && message?.body?.split("|").length >= 2) ||
      message?.dataJson?.message?.locationMessage
    ) {
      try {
        let lat, lng, name, address, imageLocation, linkLocation;

        if (message?.dataJson?.message?.locationMessage) {
          const locationMessage = message.dataJson.message.locationMessage;
          lat = locationMessage.degreesLatitude;
          lng = locationMessage.degreesLongitude;
          name = locationMessage.name;
          address = locationMessage.address;
          linkLocation = `https://www.google.com/maps?q=${lat},${lng}`;

        } else {
          let [maybeImage, maybeLink] = message.body.split("|");
          imageLocation = maybeImage.trim();
          linkLocation = maybeLink?.trim();
        }

        if (imageLocation && imageLocation.length > 100) {
          return (
            <LocationPreview
              link={linkLocation}
              image={imageLocation}
              latitude={lat}
              longitude={lng}
              name={name}
              address={address}
            />
          );
        } else {
          return (
            <LocationPreview
              link={linkLocation}
              latitude={lat}
              longitude={lng}
              name={name}
              address={address}
            />
          );
        }
      } catch (err) {
        console.log("checkMessageLocation ~ err", err);
      }
    }
  }


  const checkMessageMedia = (message) => {
    if (message?.mediaType !== "contactMessage") {
      if (
        message?.mediaUrl &&
        !["PENDING", "ERROR"].includes(message?.mediaUrl.slice(-7))
      ) {

        const baseUrl = `chatme-app/messages/${idGraf}/`;
        let mediaUrl = message?.mediaUrl.startsWith(baseUrl)
          ? message?.mediaUrl
          : baseUrl + message?.mediaUrl;


        if (message.mediaNameOriginal?.endsWith(".cdr")) {
          return <OtherCors otherUrl={mediaUrl} message={message} />;
        }

        switch (message.mediaType) {
          case "image":
            return (
              <>
                <MessageImage
                  imageUrl={mediaUrl}
                  imagesList={imagesList}
                  currentIndex={modalCurrentIndex}
                  openModal={openModalMedia}
                />
              </>
            );
          case "audio":
            return <AudioCors audioUrl={mediaUrl} message={message} />;
          case "video":
            return !message.isDeleted ? (
              <MessageVideo
                videoUrl={mediaUrl}
                openModal={openModalMedia}
                message={message}
              />
            ) : null;
          default:
        }
      } else if (
        message?.mediaUrl?.endsWith("ERROR") ||
        message?.mediaUrl === "ERROR"
      ) {
        return <span>Erro ao processar mensagem. Solicite o reencaminhamento.</span>;
      } else {
        return (
          <span className={classes.loadingMessage}>
            <CircularProgress className={classes.circleLoading} />
          </span>
        );
      }
    } else {

      let array = message?.body?.split("\n") || [];
      let obj = [];
      let contact = "";

      for (let line of array) {
        let values = line.split(":");
        for (let part of values) {
          if (part.indexOf("+") !== -1) {
            obj.push({ number: part });
          }
          if (part.indexOf("FN") !== -1) {
            // Próximo item é o nome do contato
            contact = values[values.indexOf(part) + 1];
          }
        }
      }

      return <VcardPreview contact={contact} numbers={obj[0]?.number} />;
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4 || message.ack === 5) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    const messageDate = parseISO(message.createdAt);
    const isMessageToday = isToday(messageDate);
    const isNewDay = index === 0 || !isSameDay(messageDate, parseISO(messagesList[index - 1].createdAt));

    if (isNewDay) {
      return (
        <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
          <div className={classes.dailyTimestampText}>
            {isMessageToday ? "Hoje" : format(messageDate, "dd/MM/yyyy")}
          </div>
        </span>
      );
    }

    if (index === messagesList.length - 1) {
      let renderLastMessageRef = (
        <div key={`ref-${message.createdAt}`} ref={lastMessageRef} style={{ float: "left", clear: "both" }} />
      );

      if (!isNewDay) {
        return renderLastMessageRef;
      } else {
        return (
          <>
            <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
              <div className={classes.dailyTimestampText}>
                {isMessageToday ? "Hoje" : format(messageDate, "dd/MM/yyyy")}
              </div>
            </span>
            {renderLastMessageRef}
          </>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}
          {message.quotedMsg?.body}
        </div>
      </div>
    );
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        const isSelected = selectedMessages.includes(message.id);
        const isLast = index === messagesList.length - 1;

        const baseUrl = `chatme-app/messages/${idGraf}/`;
        let mediaUrl = message?.mediaUrl?.startsWith(baseUrl)
          ? message?.mediaUrl
          : baseUrl + message?.mediaUrl;

        if (message.mediaType === "call_log") {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div className={classes.messageCenter}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>

                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                    width="20"
                    height="17"
                  >
                    <path
                      fill="#df3333"
                      d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"
                    ></path>
                  </svg>{" "}
                  <span>
                    Chamada de voz/vídeo perdida às{" "}
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
        if (!message.fromMe) {
          const messageClass =
            message?.mediaUrl?.endsWith("ERROR") || message?.mediaUrl === "ERROR"
              ? classes.messageLeftError
              : classes.messageLeft;

          const messageStyle = {
            maxWidth: message?.mediaUrl ? 265 : 600,
            backgroundColor: message?.mediaType === "liveLocationMessage" ||
              message?.dataJson?.message?.liveLocationMessage ? "#FFF8F8" : "",
          };

          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div
                className={
                  isForwardingMode && selectedMessages.includes(message.id)
                    ? classes.msgClientSelected
                    : classes.msgClientHoverToSelected
                }
                onClick={(e) => {
                  if (isForwardingMode && !e.target.closest("input[type='checkbox']")) {
                    handleToggleSelectMessage(message.id);
                  }
                }}
              >
                {isForwardingMode && (
                  <div className={classes.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleToggleSelectMessage(message.id);
                      }}
                      className={classes.checkbox}
                    />
                  </div>
                )}
                <div className={`${messageClass}`} style={{ ...messageStyle }} ref={isLast ? lastMessageRef : null}>
                  {!isForwardingMode && (
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      style={{ float: "right", marginLeft: "5px" }}
                    >
                      <ExpandMore />
                    </IconButton>
                  )}

                  {isGroup && (
                    <span className={classes.messageContactName}>
                      {message.contact?.name}
                    </span>
                  )}
                  {(message.mediaUrl || message.mediaType === "contactMessage") &&
                    checkMessageMedia(message)}
                  {(message.mediaType === "locationMessage" || message.mediaType === "liveLocationMessage") && checkMessageLocation(message)}

                  <div
                    className={clsx(classes.textContentItem, {
                      [classes.textContentItemDeleted]: message.isDeleted,
                    })}
                  >
                    {message.quotedMsg && renderQuotedMessage(message)}
                    {message.isDeleted && (
                      <Block
                        color="disabled"
                        fontSize="small"
                        className={classes.deletedIcon}
                      />
                    )}

                    {message.mediaType &&
                      !(
                        message.body === 'audio' ||
                        message.body === 'sticker' ||
                        message.body === 'undefined' ||
                        message?.mediaUrl?.endsWith("ERROR") ||
                        message?.mediaUrl === "ERROR" ||
                        (message.mediaType === 'audio' && /\.(mp3|wav|aac)$/i.test(message.body)) ||
                        (message.mediaType === 'video' && /\.(mp4|avi|mkv)$/i.test(message.body)) ||
                        (message.mediaType === 'image' && /\.(jpg|png|jpeg)$/i.test(message.body))
                      ) ? (
                      <MarkdownWrapper isEdited={message.editedMessage} message={message} mediaUrl={mediaUrl}>
                        {message.body || message.dataJson?.message?.ephemeralMessage?.message?.extendedTextMessage?.text}
                      </MarkdownWrapper>
                    ) : (
                      <div style={{ padding: "6px" }}></div>
                    )}

                    <span className={classes.timestamp}>
                      <span className={classes.editedMessageFromClient}>
                        {message?.editedMessage && "Editada"}
                      </span>
                      {format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          const messageClass =
            message?.mediaUrl?.endsWith("ERROR") || message?.mediaUrl === "ERROR"
              ? classes.messageRightError
              : classes.messageRight;

          const messageStyle = {
            maxWidth: message?.mediaUrl ? 265 : 600,
            backgroundColor: message?.mediaType === "liveLocationMessage" ||
              message?.dataJson?.message?.liveLocationMessage ? "#FFF8F8" : "",
          };

          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div
                className={
                  isForwardingMode && selectedMessages.includes(message.id)
                    ? classes.selectedMessageFromMe
                    : classes.selectedHoverMessageFormMe
                }
                style={{
                  justifyContent: isForwardingMode ? "space-between" : "flex-end",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={(e) => {
                  if (isForwardingMode && !e.target.closest("input[type='checkbox']")) {
                    handleToggleSelectMessage(message.id);
                  }
                }}
              >
                {isForwardingMode && (
                  <div className={classes.checkboxContainer} style={{ marginRight: "10px" }}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleToggleSelectMessage(message.id);
                      }}
                      className={classes.checkbox}
                    />
                  </div>
                )}
                <div className={`${messageClass}`} style={{ ...messageStyle }} ref={isLast ? lastMessageRef : null}>
                  {!isForwardingMode && (
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ExpandMore />
                    </IconButton>
                  )}

                  {(message.mediaUrl || message.mediaType === "contactMessage") && checkMessageMedia(message)}
                  {(message.mediaType === "locationMessage" || message.mediaType === "liveLocationMessage") && checkMessageLocation(message)}
                  <div
                    className={clsx(classes.textContentItem, {
                      [classes.textContentItemDeleted]: message.isDeleted,
                    })}
                  >
                    {message.isDeleted && (
                      <Block
                        color="disabled"
                        fontSize="small"
                        className={classes.deletedIcon}
                      />
                    )}
                    {message.quotedMsg && renderQuotedMessage(message)}
                    {message.mediaType &&
                      !(message.body === 'audio' || message.body === 'sticker' || message.body === 'undefined' ||
                        (message.mediaType === 'audio' && /\.(mp3|wav|aac)$/i.test(message.body)) ||
                        (message.mediaType === 'video' && /\.(mp4|avi|mkv)$/i.test(message.body)) ||
                        (message.mediaType === 'image' && /\.(jpg|png|jpeg)$/i.test(message.body)))
                      ? (
                        <MarkdownWrapper isEdited={message.editedMessage} message={message} mediaUrl={mediaUrl}>
                          {message.body ? message.body : message.dataJson?.message?.ephemeralMessage?.message?.extendedTextMessage?.text}
                        </MarkdownWrapper>
                      ) : (
                        <div style={{ padding: "6px" }}></div>
                      )}

                    {message?.editedMessage && <div className={classes.spacing}></div>}

                    <span className={classes.timestamp}>
                      <span className={classes.editedMessageFromMe}>
                        {message?.editedMessage && 'Editada'}
                      </span>
                      {format(parseISO(message.createdAt), 'HH:mm')}
                      {renderMessageAck(message)}
                    </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
        handleFowardMessage={handleFowardMessage}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
      </div>
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}

      {/* Modal para visualizar Imagens/Vídeos */}
      {modalOpen && (
        <ModalMedia
          open={modalOpen}
          onClose={handleModalClose}
          mediaUrl={modalMediaUrl}
          mediaType={modalMediaType}
          onPrev={handleModalPrev}
          onNext={handleModalNext}
        />
      )}
    </div>
  );
};

export default MessagesList;
