import React from "react";
import { createTheme, withStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useStyles } from "./style";

export const theme = createTheme({
    typography: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    },
    palette: {
        primary: {
            main: "#2196F3",
        },
        secondary: {
            main: "#0000",
        },
    },
    overrides: {
        MuiBadge: {
            colorPrimary: {
                backgroundColor: "#DCEFFF",
                color: "#2196F3"
            },
            colorSecondary: {
                backgroundColor: "#B0DBFD",
            },
        },
        MuiTab: {
            root: {
                "&$selected": {
                    backgroundColor: "",
                    color: "#2196F3",
                },
            },
            textColorInherit: {
                color: "#2196F3",
                "&$selected": {
                    color: "#2196F3",
                },
            },
        },

        MuiMenuItem: {
            root: {
                "&$selected": {
                    backgroundColor: "transparent !important",
                },
                "&$selected:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.08) !important",
                },
            },
        },
    },
});

export const GreenTab = withStyles({
    root: {
        flexGrow: 1,
        color: "#757575",
        "&$selected": {
            color: "#00A825 !important",
        },
    },
    selected: {},
})(Tab);

export const CustomTabs = (props) => {
    const classes = useStyles(props);
    const tabsClasses = {
        root: classes.tabsRoot,
        indicator: classes.indicator,
    };

    return <Tabs {...props} classes={tabsClasses} />;
};
