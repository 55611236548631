import React from "react"
import useS3 from "../../hooks/useS3";



const AudioCors = ({ audioUrl}) => {
  const { blobUrl } = useS3(audioUrl)


  return (
    blobUrl ? (
      <div >
        <audio controls style={{ maxWidth: '256px' }}>
          <source src={blobUrl} type="audio/ogg" />
        </audio>

      </div>
    ) : null
  );
};

export default AudioCors;
