import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",                
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    overflowY: "auto",            
    overflowX: "hidden",
    ...theme.scrollbarStyles,     
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
    marginBottom: "-20px",
    position: "relative",
    maxHeight: "900px",           
  },

  
  sectionHeader: {
    color: '#329EF4',
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 5,
    fontSize: 16,
    fontWeight: 600,
  },

  sectionContainer: {
    marginBottom: 15,
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },
  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  loadingSkeleton: {
    margin: "15px auto",
    display: "block",
    width: "90%",
  },
}));
