import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputMask from "react-input-mask";

import { i18n } from "../../translate/i18n";

import { api } from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { FlagIcon } from "react-flag-kit";
import zoneCodeCountryMap from "../../utils/zoneCodeCountryMap";
import countryMasks from "../../utils/countryMasks";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textFieldZipCode: {
    width: '77px',
    marginRight: theme.spacing(1),
    flex: 1,
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  flagIcon: {
    width: '42px',
    marginTop: '10px',
    marginLeft: '-4px',
    marginRight: '3px',
    height: '35px',
    borderRadius: '4px'
  }
}));


const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório"),
  zoneCode: Yup.string().required("Obrigatório"),
  number: Yup.string()
    .required("Obrigatório")
    .test('valid-number', 'Número inválido', function (value) {
      const { zoneCode } = this.parent;
      const countryCode = zoneCodeCountryMap[zoneCode];
      if (!countryCode) return false;
      const mask = countryMasks[countryCode];
      const digitsOnly = value ? value.replace(/\D/g, '') : '';
      const maskDigitsOnly = mask.replace(/\D/g, '');

      if (countryCode === 'BR') {
        return digitsOnly.length === 10 || digitsOnly.length === 11;
      } else {
        return digitsOnly.length === maskDigitsOnly.length;
      }
    }),
  email: Yup.string().email("Invalid email"),
});


const ContactModal = ({ open, onClose, contactId, initialValues, onSave, updateList }) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const initialState = {
    name: "",
    number: "",
    email: "",
    zoneCode: "+55",
  };

  const [contact, setContact] = useState(initialState);
  const [currentZoneCode, setCurrentZoneCode] = useState("+55");
  const [numberMask, setNumberMask] = useState("");

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact({
          name: initialValues.name || "",
          number: initialValues.number || "",
          email: initialValues.email || "",
          zoneCode: `+${initialValues.zoneCode}` || "",
        });
        setCurrentZoneCode(`+${initialValues.zoneCode}` || "");
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          setContact({
            name: data.name || "",
            number: data.number || "",
            email: data.email || "",
            zoneCode: `+${data.zoneCode}` || "",
          });
          setCurrentZoneCode(`+${data.zoneCode}` || "");
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
    setCurrentZoneCode("+55");
  };


  useEffect(() => {
    const countryCode = zoneCodeCountryMap[currentZoneCode];
    if (countryCode) {
      if (countryCode === 'BR') {
        setNumberMask('(99) 99999-9999');
      } else {
        setNumberMask(countryMasks[countryCode]);
      }
    }
  }, [currentZoneCode]);


  const getFlagForZoneCode = (zoneCode) => {
    const countryCode = zoneCodeCountryMap[zoneCode];
    return countryCode ? <span><FlagIcon code={countryCode} size={24} className={classes.flagIcon} /></span> : null;
  };

  const handleSaveContact = async values => {
    try {
      const cleanValues = {
        ...values,
        zoneCode: values.zoneCode.replace('+', ''),
        number: values.number.replace(/\D/g, ''),
        userId: user.id
      };
      if (contactId) {
        await api.put(`/contacts/${contactId}`, cleanValues);
        if (updateList) updateList();
        handleClose();
      } else {
        const { data } = await api.post(`/contacts`, cleanValues);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"), {
        position: toast.POSITION.BOTTOM_CENTER
      });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          {contactId ? i18n.t("contactModal.title.edit") : i18n.t("contactModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleBlur, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  {i18n.t("contactModal.form.mainInfo")}
                </Typography>
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.name")}
                  name="name"
                  autoFocus
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />


                <Field name="zoneCode" >
                  {({ field }) => (
                    <InputMask
                      mask="+9999"
                      value={field.value}
                      onChange={(e) => {
                        handleChange(e);
                        setCurrentZoneCode(e.target.value);
                        setFieldValue('number', '');
                      }}
                      onBlur={handleBlur}
                      name={field.name}
                      id={field.name}
                      maskChar=""
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          variant="outlined"
                          margin="dense"
                          label={i18n.t("contactModal.form.zoneCode")}
                          className={classes.textFieldZipCode}
                          error={touched.zoneCode && Boolean(errors.zoneCode)}
                          helperText={touched.zoneCode && errors.zoneCode}
                        />
                      )}
                    </InputMask>
                  )}
                </Field>
                {getFlagForZoneCode(currentZoneCode)}


                <Field name="number">
                  {({ field }) => (
                    <InputMask
                      mask={numberMask}
                      value={field.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={field.name}
                      maskChar=""
                      alwaysShowMask={false}
                      id={field.name}
                      disabled={!values.zoneCode}
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          label={i18n.t("contactModal.form.number")}
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                          error={touched.number && Boolean(errors.number)}
                          helperText={touched.number && errors.number}
                          placeholder={values.number ? "" : "13912344321"}
                          disabled={!values.zoneCode}  // Disable if zoneCode is empty or zero
                        />
                      )}
                    </InputMask>
                  )}
                </Field>

                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("contactModal.form.email")}
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    placeholder="Email address"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </div>
                <Typography style={{ marginBottom: 8, marginTop: 12 }} variant="subtitle1">
                  {i18n.t("contactModal.form.extraInfo")}
                </Typography>

                <FieldArray name="extraInfo">
                  {({ push, remove }) => (
                    <>
                      {values.extraInfo && values.extraInfo.length > 0 &&
                        values.extraInfo.map((info, index) => (
                          <div className={classes.extraAttr} key={`${index}-info`}>
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraName")}
                              name={`extraInfo[${index}].name`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraValue")}
                              name={`extraInfo[${index}].value`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <IconButton size="small" onClick={() => remove(index)}>
                              <DeleteOutlineIcon />
                            </IconButton>
                          </div>
                        ))}
                      <div className={classes.extraAttr}>
                        <Button
                          style={{ flex: 1, marginTop: 8 }}
                          variant="outlined"
                          color="primary"
                          onClick={() => push({ name: "", value: "" })}
                        >
                          {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={isSubmitting} variant="outlined">
                  {i18n.t("contactModal.buttons.cancel")}
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting} variant="contained" className={classes.btnWrapper}>
                  {contactId ? i18n.t("contactModal.buttons.okEdit") : i18n.t("contactModal.buttons.okAdd")}
                  {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ContactModal;
