import React, { useEffect, useState, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { api } from "../../services/api";
import { AvatarWithPicture } from "../TicketListItemOpen";

const MAX_SELECTION = 5;

const useStyles = makeStyles((theme) => ({
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 410,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    display: "flex",
    flexDirection: "column",
    maxHeight: "80vh",
  },
  header: {
    marginTop: "9px",
    padding: theme.spacing(2, 4, 1),
  },
  searchField: {
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      height: 40,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "#fff",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      "& fieldset": {
        borderColor: grey[300],
      },
      "&:hover fieldset": {
        borderColor: grey[400],
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2576D2",
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 12px",
    },
  },
  contactItem: {
    marginBottom: 0,
    marginLeft: 3,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
  },
  selectedContact: {
    backgroundColor: grey[200],
  },
  contactName: {
    marginTop: -15,
    fontWeight: 500,
    fontSize: 15,
  },
  listOuterContainer: {
    flex: 1,
    overflow: "hidden",
  },
  listContainer: {
    overflowY: "auto",
    maxHeight: "calc(80vh - 150px)",
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: 13,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: grey[400],
      borderRadius: 10,
      border: `3px solid ${theme.palette.background.paper}`,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: grey[200],
      borderRadius: 10,
    },
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: -30,
  },
  checkbox: {
    marginRight: theme.spacing(2),
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: "#fff",
    position: "fixed",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    zIndex: 1000,
    padding: theme.spacing(1, 2),
    boxSizing: "border-box",
  },
  selectedContactsFooter: {
    display: "flex",
    overflowX: "auto",
    width: "100%",
    paddingBottom: theme.spacing(1),
    boxSizing: "border-box",
    gap: theme.spacing(1),
  },
  footerButtons: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    height: 36,
    padding: theme.spacing(1, 3),
    margin: theme.spacing(0, 1),
    fontWeight: "bold",
    width: 170,
  },
  cancelButton: {
    borderColor: "#fb6155",
    color: theme.palette.error.main,
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.palette.error.main,
      borderColor: theme.palette.error.dark,
    },
  },
  sendButton: {
    backgroundColor: "#2576D2",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0056b3",
    },
  },
  recentTitle: {
    marginLeft: 20,
    marginBottom: 20,
    color: "#A29A9A",
    fontSize: 16,
    fontWeight: 500,
  },
  boxLoader: {
    width: 410,
    height: 434,
  },
  boxLoaderContent: {
    width: "90%",
    height: "762.391px",
    marginLeft: 38,
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 200,
  },
  loaderPagination: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}));

const ContactListModal = ({ onClose }) => {
  const classes = useStyles();

  // "contacts" -> array de contatos da pesquisa atual
  const [contacts, setContacts] = useState([]);
  // "selectedContactsIds" -> IDs selecionados para uso rápido
  const [selectedContactsIds, setSelectedContactsIds] = useState([]);
  // "selectedContactsMap" -> Mapeia id -> objeto do contato, garantindo que 
  //   continuamos tendo dados mesmo se ele sumir de "contacts".
  const [selectedContactsMap, setSelectedContactsMap] = useState({});

  // debounce
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const idGraf = localStorage.getItem("idGraf");
  const listRef = useRef(null);

  // Debounce
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 400);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Buscar contatos no servidor
  const fetchContacts = async (page = 1, newSearchTerm = debouncedSearch) => {
    setIsLoading(true);
    try {
      const response = await api.get("/contacts/fowarding-list", {
        params: {
          page,
          searchTerm: newSearchTerm,
          idGraf,
        },
      });

      const fetchedContacts = response.data.contacts;
      setHasMore(response.data.hasMore);

      if (page === 1) {
        setContacts(fetchedContacts);
      } else {
        const combined = [...contacts, ...fetchedContacts];
        // remove duplicados
        const uniqueMap = new Map();
        combined.forEach((c) => uniqueMap.set(c.id, c));
        setContacts(Array.from(uniqueMap.values()));
      }
    } catch (error) {
      console.error("Erro ao carregar contatos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Sempre que debouncedSearch mudar, buscamos da página 1
  useEffect(() => {
    setPageNumber(1);
    fetchContacts(1, debouncedSearch).then(() => {
      // Se não tem busca, rola pro topo
      if (listRef.current && !debouncedSearch) {
        listRef.current.scrollTop = 0;
      }
    });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  // Paginação "infinite scroll"
  useEffect(() => {
    if (pageNumber > 1) {
      fetchContacts(pageNumber, debouncedSearch);
    }
    // eslint-disable-next-line
  }, [pageNumber]);

  // Handler do scroll
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore && !isLoading) {
      setPageNumber((prev) => prev + 1);
    }
  };

  // Ao clicar num contato, adiciona ou remove dos selecionados
  // Precisamos guardar também o objeto "contato" em selectedContactsMap
  const handleToggleSelectContact = (contact) => {
    setSelectedContactsIds((prevIds) => {
      const alreadySelected = prevIds.includes(contact.id);
      // se já selecionado, remove
      if (alreadySelected) {
        return prevIds.filter((id) => id !== contact.id);
      } else {
        // se não, adiciona se não ultrapassar MAX_SELECTION
        if (prevIds.length >= MAX_SELECTION) return prevIds;
        return [contact.id, ...prevIds];
      }
    });

    // guarda ou remove do map
    setSelectedContactsMap((prevMap) => {
      const newMap = { ...prevMap };
      if (newMap[contact.id]) {
        delete newMap[contact.id];
      } else {
        newMap[contact.id] = contact;
      }
      return newMap;
    });
  };

  // Checa se um contato está selecionado
  const isContactSelected = (contactId) => {
    return selectedContactsIds.includes(contactId);
  };

  // checa se está desabilitado (quando já atingimos MAX_SELECTION)
  const isContactDisabled = (contactId) => {
    return !isContactSelected(contactId) && selectedContactsIds.length >= MAX_SELECTION;
  };

  // Campo de busca
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Renderiza item
  const renderContactItem = (contact) => {
    const isSelected = isContactSelected(contact.id);
    const isDisabled = isContactDisabled(contact.id);

    return (
      <ListItem
        key={contact.id}
        button
        className={`${classes.contactItem} ${isSelected ? classes.selectedContact : ""}`}
        onClick={(e) => {
          // se estiver desabilitado, não faz nada
          if (isDisabled) return;
          // se o clique não for no checkbox, chama handleToggleSelectContact
          if (!e.target.closest("input[type='checkbox']")) {
            handleToggleSelectContact(contact);
          }
        }}
      >
        <ListItemAvatar>
          <AvatarWithPicture
            profilePicUrl={contact.profilePicUrl}
            contactName={contact.name}
            idGraf={idGraf}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.contactName} noWrap>
              {contact.name}
            </Typography>
          }
        />
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={isSelected}
            disabled={isDisabled}
            onChange={(e) => {
              e.stopPropagation();
              if (!isDisabled) {
                handleToggleSelectContact(contact);
              }
            }}
            className={classes.checkbox}
            color="primary"
          />
        </div>
      </ListItem>
    );
  };

  // Para exibir as Chips, iteramos no Map de selecionados,
  // assim mesmo que o contato não esteja mais em "contacts", ele fica selecionado.
  const selectedContactsArray = Object.values(selectedContactsMap);

  return (
    <Modal open={true} onClose={onClose}>
      <Paper className={classes.modalContent}>
        <div className={classes.header}>
          <Typography variant="h6" gutterBottom>
            Encaminhar para...
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Pesquisar"
            fullWidth
            className={classes.searchField}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        <div className={classes.listOuterContainer}>
          {isLoading && contacts.length === 0 ? (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          ) : contacts.length === 0 ? (
            <List className={classes.boxLoader}>
              <div className={classes.boxLoaderContent}>
                <Typography>Não encontramos nenhum contato com esse nome</Typography>
              </div>
            </List>
          ) : (
            <div
              ref={listRef}
              className={`${classes.listContainer} ${classes.scrollbar}`}
              onScroll={handleScroll}
            >
              {contacts.length > 0 && (
                <>
                  <Typography variant="h6" className={classes.recentTitle}>
                    Contatos
                  </Typography>
                  {contacts.map(renderContactItem)}
                </>
              )}
              {isLoading && pageNumber > 1 && (
                <div className={classes.loaderPagination}>
                  <CircularProgress size={24} />
                </div>
              )}
            </div>
          )}
        </div>

        <div className={classes.footer}>
          <div className={classes.selectedContactsFooter}>
            {selectedContactsArray.map((contact) => (
              <Chip
                key={contact.id}
                size="small"
                avatar={
                  <AvatarWithPicture
                    profilePicUrl={contact.profilePicUrl}
                    contactName={contact.name}
                    idGraf={idGraf}
                    small
                  />
                }
                label={contact.name}
                onDelete={() => handleToggleSelectContact(contact)}
                deleteIcon={<CloseIcon />}
              />
            ))}
          </div>
          <div className={classes.footerButtons}>
            <Button
              variant="outlined"
              className={`${classes.button} ${classes.cancelButton}`}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className={`${classes.button} ${classes.sendButton}`}
              onClick={onClose}
            >
              Encaminhar
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export default ContactListModal;
