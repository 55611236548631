import React, { useState, useEffect, useRef, useContext } from "react";
import { api } from "../../services/api";
import TicketsSkeleton from "../../components/TicketsListSkeleton";
import TicketListItem from "../TicketListItem";
import { useStyles } from "./css/style";
import PubSub from 'pubsub-js';
import { AuthContext } from "../../context/Auth/AuthContext";

function deduplicateArray(tickets) {
  const map = new Map();
  for (const t of tickets) {
    map.set(t.id, t);
  }
  return Array.from(map.values());
}

function TicketList({ searchParam }) {
  const classes = useStyles();
  const containerRef = useRef(null);

  const [conversationTickets, setConversationTickets] = useState([]);
  const [messageTickets, setMessageTickets] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setConversationTickets([]);
    setMessageTickets([]);
    setPageNumber(1);
  }, [searchParam]);


  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      setLoading(true);
      try {
        const { data } = await api.get("/tickets", {
          params: { searchParam, pageNumber }
        });
        if (!cancel && data) {
          let conv = data.conversationTickets || [];
          let msg = data.messageTickets || [];

          conv = deduplicateArray(conv);
          msg = deduplicateArray(msg);

          setConversationTickets(prev => {
            const combined = [...prev, ...conv];
            const deduped = deduplicateArray(combined);
            return deduped;
          });

          setMessageTickets(prev => {
            let combined = [...prev, ...msg];
            combined = deduplicateArray(combined);
            return combined;
          });
          setHasMore(data.hasMore);
        }
      } catch (err) {
        console.error("Erro ao buscar tickets:", err);
      } finally {
        if (!cancel) setLoading(false);
      }
    }

    fetchData();
    return () => {
      cancel = true;
    };
  }, [searchParam, pageNumber]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (!loading && hasMore && scrollHeight - scrollTop <= clientHeight + 50) {
      setPageNumber(prev => prev + 1);
    }
  };

  const noTicketsFound =
    !loading && conversationTickets.length === 0 && messageTickets.length === 0;

  const handleTicketStatusChange = (ticketId) => {
    PubSub.publish("TICKET_ACTION_UPDATED", { newStatus: "transferTicketBySearchingBox", ticketId: ticketId });
    PubSub.publish("USER_ID_TRANSFER", { userId: user?.id });
  };


  return (
    <div className={classes.ticketsListWrapper}>
      <div
        className={classes.ticketsList}
        onScroll={handleScroll}
        ref={containerRef}
      >
        {loading && conversationTickets.length === 0 && messageTickets.length === 0 && (
          <TicketsSkeleton />
        )}

        {noTicketsFound && (
          <div className={classes.noTicketsDiv}>
            <span className={classes.noTicketsTitle}>Nenhum ticket encontrado</span>
            <p className={classes.noTicketsText}>
              Não encontramos resultados para a sua busca
            </p>
          </div>
        )}

        {conversationTickets.length > 0 && (
          <>
            <h3 className={classes.sectionHeader}>Conversas</h3>
            <div className={classes.sectionContainer}>
              {conversationTickets.map(ticket => (
                <TicketListItem key={ticket.id} ticket={ticket} onTicketStatusChange={handleTicketStatusChange} />
              ))}
            </div>
          </>
        )}

        {messageTickets.length > 0 && (
          <>
            <h3 className={classes.sectionHeader}>Mensagens</h3>
            <div className={classes.sectionContainer}>
              {messageTickets.map(ticket => (
                <TicketListItem key={ticket.id} ticket={ticket} onTicketStatusChange={handleTicketStatusChange} />
              ))}
            </div>
          </>
        )}

        {loading && (conversationTickets.length > 0 || messageTickets.length > 0) && (
          <TicketsSkeleton />
        )}
      </div>
    </div>
  );
}

export default TicketList;
