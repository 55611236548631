import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SettingsIcon from "@material-ui/icons/Settings";
import { CameraAlt, PhoneIphone } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";

import toastError from "../../errors/toastError";
import { api } from "../../services/api";
import connectToSocket from "../../services/socket-io";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: 16,
    maxWidth: 360,
    margin: "0 auto",
  },
  container: {
    backgroundColor: "#003584",
    color: "#fff",
    padding: theme.spacing(3),
    textAlign: "center",
    borderRadius: 16,
    position: "relative",
    maxHeight: "calc(100vh - 64px)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      borderRadius: "3px",
    },
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(255,255,255,0.3) transparent",
  },
  closeIcon: {
    position: "absolute",
    top: 8,
    right: 8,
    color: "#fff",
  },
  whatsappHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
    borderRadius: 8,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: "#fff",
  },
  headerText: {
    fontWeight: 600,
    fontSize: 20,
  },
  qrWrapper: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: 4,
    display: "inline-block",
    marginBottom: theme.spacing(1),
  },
  qrImage: {
    width: 210,
    height: 210,
    borderRadius: 4,
    objectFit: "cover",
    transition: "opacity 0.3s ease",
  },
  reloadIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
  },
  loadingSpinner: {
    margin: theme.spacing(2, 0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    fontSize: "0.9rem",
    textAlign: "left",
  },
  divisor: {
    border: "0.5px solid rgb(46 83 137)",
  },
  inlineIcon: {
    verticalAlign: "middle",
    marginLeft: theme.spacing(0.5),
    marginBottom: "4px",
    fontSize: "25px",
  },
}));

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();

  const [qrCode, setQrCode] = useState("");
  const [qrExpired, setQrExpired] = useState(false);
  const [reloading, setReloading] = useState(false);

  const timerRef = useRef(null);
  const idGraf = localStorage.getItem("idGraf");

  const startTimer = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setQrExpired(true);
    }, 20000);
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const fetchSession = useCallback(async () => {
    if (!whatsAppId) return;
    try {
      const { data } = await api.get(`/whatsapp/${whatsAppId}`);
      setQrCode(data.qrcode);
      setQrExpired(false);
      startTimer();
    } catch (err) {
      toastError(err);
    }
  }, [whatsAppId, startTimer]);

  const fetchNewQrCode = useCallback(async () => {
    if (!whatsAppId) return;
    setReloading(true);
    try {
      await api.get(`/whatsapp/${whatsAppId}/generate-qr`);
    } catch (err) {
      toastError(err);
    } finally {
      setReloading(false);
    }
  }, [whatsAppId]);

  useEffect(() => {
    if (!open) return;
    fetchSession();
  }, [open, fetchSession]);

  useEffect(() => {
    if (!whatsAppId) return;
    const socket = connectToSocket();

    socket.on(`whatsappSession-${idGraf}`, (data) => {
      console.log("Socket incoming =>", data);
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
        setQrExpired(false);
        setReloading(false);
        startTimer();
      }
      if (data.action === "update" && data.session.qrcode === "") {
        onClose();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onClose, idGraf, startTimer]);

  const handleReload = async () => {
    await fetchNewQrCode();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.container}>
        <IconButton className={classes.closeIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Box className={classes.whatsappHeader}>
          <WhatsAppIcon style={{ fontSize: 35 }} />
          <Typography variant="subtitle1" className={classes.headerText}>
            WhatsApp
          </Typography>
        </Box>

        <Box
          className={classes.qrWrapper}
          style={{ backgroundColor: qrCode ? "" : "rgba(255, 255, 255, 0.0)" }}
        >
          {qrCode ? (
            <>
              <img
                src={qrCode}
                alt="QR Code"
                className={classes.qrImage}
                style={{ opacity: qrExpired ? 0.1 : 1 }}
              />

              {qrExpired && (
                <IconButton
                  className={classes.reloadIcon}
                  style={{
                    backgroundColor: "#000000cc",
                    color: "#fff",
                  }}
                  onClick={handleReload}
                  disabled={reloading}
                >
                  {reloading ? (
                    <CircularProgress size={30} />
                  ) : (
                    <RefreshIcon style={{ fontSize: 30 }} />
                  )}
                </IconButton>
              )}
            </>
          ) : (
            <CircularProgress
              className={classes.loadingSpinner}
              style={{ color: qrCode ? "" : "#fff" }}
            />
          )}
        </Box>

        <hr className={classes.divisor} />

        <Box className={classes.instructions}>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ fontWeight: "bold", color: "#fff" }}
          >
            Como Conectar:
          </Typography>

          <Box display="flex" alignItems="center" mb={1}>
            <Box mr={1}>
              <PhoneIphone style={{ color: "#fff" }} />
            </Box>
            <Typography variant="body2" style={{ color: "#fff" }}>
              Abra o WhatsApp no seu celular.
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mb={1}>
            <Box mr={1}>
              <SettingsIcon style={{ color: "#fff" }} />
            </Box>
            <Typography variant="body2" style={{ color: "#fff" }}>
              Acesse o menu e selecione <strong>"WhatsApp Web"</strong> ou{" "}
              <strong>"Aparelhos Conectados"</strong>.
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <CameraAlt style={{ color: "#fff" }} />
            </Box>
            <Typography variant="body2" style={{ color: "#fff" }}>
              Escaneie o QR Code exibido na tela.
            </Typography>
          </Box>

          <Typography
            variant="caption"
            display="block"
            style={{ marginTop: 8, color: "#fff" }}
          >
            Obs.: O QR Code expira a cada 20 segundos. Para gerar um novo,
            clique em:
            <RefreshIcon className={classes.inlineIcon} />
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(QrcodeModal);
